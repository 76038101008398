import { UrlParamsReplace } from './index';

export const GET_REFERRAL_DATA_URL = () => UrlParamsReplace('/referral/', {});

export const CREATE_USER_LINK_WITH_IFA_URL = () =>
    UrlParamsReplace('/referees');

export const GET_REFERRAL_DATA_LIST_URL = () =>
    UrlParamsReplace('/referee-list/', {});

export const CHECK_REFERRAL_CODE_URL = (code) =>
    UrlParamsReplace('/referrals/:code/valid', { code });

export const CHECK_USER_REWARD_ELIGIBILITY = () =>
    UrlParamsReplace('/reward_eligibility/user', {});
