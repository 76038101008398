import { hasWindow } from '@services/http.service';
import * as SsrHttpService from '@http/ssr-http.service';
import * as CsrHttpService from '@http/csr-http.service';

import {
    CREATE_USER_LINK_WITH_IFA_URL,
    GET_REFERRAL_DATA_LIST_URL,
    GET_REFERRAL_DATA_URL,
    CHECK_REFERRAL_CODE_URL,
    CHECK_USER_REWARD_ELIGIBILITY,
} from '@services/urls/referral';

export const getReferralData = (ctx) => {
    const url = GET_REFERRAL_DATA_URL();
    if (hasWindow()) {
        return CsrHttpService.getWithAuth(url);
    }
    return SsrHttpService.getWithAuth(url, ctx);
};

export const createUserLinkWithIfa = (data, ctx = null) => {
    if (hasWindow()) {
        return CsrHttpService.postWithAuth(
            CREATE_USER_LINK_WITH_IFA_URL(),
            data
        );
    }
    return SsrHttpService.postWithAuth(
        CREATE_USER_LINK_WITH_IFA_URL(),
        data,
        ctx
    );
};

export const getReferralListData = (ctx = null) => {
    const url = GET_REFERRAL_DATA_LIST_URL();
    if (hasWindow()) {
        return CsrHttpService.getWithAuth(url);
    }
    return SsrHttpService.getWithAuth(url, ctx);
};

export const checkReferralCode = (code, ctx = null) => {
    const url = CHECK_REFERRAL_CODE_URL(code);
    if (hasWindow()) {
        return CsrHttpService.postWithOutAuth(url);
    }
    return SsrHttpService.postWithOutAuth(url);
};

export const checkUserRewardEligibility = (code, ctx = null) => {
    const url = CHECK_USER_REWARD_ELIGIBILITY(code);
    if (hasWindow()) {
        return CsrHttpService.getWithAuth(url);
    }
    return SsrHttpService.getWithAuth(url, ctx);
};
