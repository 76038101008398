import { Button, Text, Modal, Image } from '@atoms/index';
import { removeReferralEntityType } from '@services/identity.service';

const ConfirmationSignUpModal = ({
    show,
    setShowConfirmationModal,
    message,
    signUp,
    setLoading,
    removeReferralCode,
}) => {
    return (
        <Modal
            show={show}
            width="w-[572px]"
            onClose={() => setShowConfirmationModal(false)}
            hideTitle={true}
            children={
                <div className="flex  flex-wrap ">
                    <div className="flex flex-row  my-2 md:mx-2 gap-4 w-full">
                        <Image src="/images/ExclamationCircle.svg" />
                        <Text
                            content={`${message}`}
                            className="text-lg md:text-xl text-gray-800 font-semibold"
                        />
                    </div>
                    <div className="flex flex-col sm:flex-row justify-center mt-6 gap-3 sm:gap-6 items-center w-full">
                        <Button
                            design="standard"
                            onClick={(e) => {
                                setLoading(false);
                                setShowConfirmationModal(false);
                            }}
                            type="ghost"
                            className="items-center py-4 px-8 !border-primary-500 border-[2px] w-full"
                            wrapperClass="w-full sm:w-auto"
                            children={
                                <Text
                                    content="Cancel"
                                    className="text-xl text-primary-500  text-center font-semibold"
                                />
                            }
                        />
                        <Button
                            onClick={(e) => {
                                setShowConfirmationModal(false);
                                removeReferralCode();
                                removeReferralEntityType();
                                signUp();
                            }}
                            design="standard"
                            type="primary"
                            className="items-center py-4 px-8 !bg-primary-500 w-full"
                            wrapperClass="w-full sm:w-auto"
                            children={
                                <Text
                                    content="Yes, Proceed"
                                    className="text-xl whitespace-nowrap text-basicWhite text-center font-semibold"
                                />
                            }
                        />
                    </div>
                </div>
            }
        />
    );
};

export default ConfirmationSignUpModal;
